import { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { ArrowBackOutlined } from "@mui/icons-material";
import { useSnackbar } from "react-simple-snackbar";
import { format } from "date-fns";

import Section from "../components/Section";
import Container from "../components/Container";
import Row from "../components/Row";
import Column from "../components/Column";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/Modal";
import BookingImg from "../components/BookingImg";
import GuestPool from "../components/GuestPool";

const TenantBookingInfo = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [booking, fetchBooking] = useState([]);
	const [primaryGuest, fetchPrimaryGuest] = useState([]);
	const [actionLoading, setActionLoading] = useState(false);
	const [activeRevision, setActiveRevision] = useState(null);
	const [bookingDate, setBookingDate] = useState(null);
	const mayFirst2023 = new Date("2023-05-01");

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/b/${id}`,
			requestOptions
		);

		const data = await response.json();

		if (!response.ok) {
			navigate("/bookings");
		} else {
			fetchBooking(data.data);
			fetchPrimaryGuest(data.primary_guest);
			setActiveRevision(data.revision_id);
			setBookingDate(new Date(data.data.created_at));
		}
		setLoading(false);
	};

	const regenQr = async () => {
		setLoading(true);
		const requestOptions = {
			method: "POST",
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/temp/qr/generate/${id}`,
			requestOptions
		);

		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
			setLoading(false);
		} else {
			getData();
		}
	};

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		document.title = `Azure | ${booking.ref_no}`;
	}, [booking]);

	return (
		<>
			<div className="tenant-header dark-bg flex-row-center">
				<Link
					to={`/bookings${window.location.search}`}
					className="back-btn flex-row-left"
				>
					<ArrowBackOutlined sx={{ fontSize: 28 }} className="white-text" />
				</Link>
				<span className="page-title white-text">{booking.ref_no}</span>
			</div>
			<Section className="tenant-content full-page white-bg">
				{loading ? (
					<div className="loading flex-column">Loading</div>
				) : (
					<div className="tenant-booking-info-container">
						{activeRevision && (
							<div className="revision-warning align-center">
								<span className="text-sm bold-text">
									Pending revision request.
								</span>
								<p className="label-md m-0">
									Your changes will be applied once the request is approved.
								</p>
							</div>
						)}
						<Tabs defaultActiveKey="info" className="mt-3 mb-4">
							<Tab
								eventKey="info"
								title="Details"
								tabClassName="tenant-booking-tab"
							>
								<Container>
									<Row className="mt-5 mb-3">
										<Column className="p-0">
											<span className="tenant-guests-header">
												Booking Details
											</span>
										</Column>
									</Row>
									<Row>
										<Column className="tenant-info-col">
											<label className="form-label">Reference No.</label>
											<span className="info-text">{booking.ref_no}</span>
										</Column>
										<Column className="tenant-info-col">
											<label className="form-label">Status</label>
											<span className="info-text">
												{booking.display_status}
											</span>
										</Column>
										{/* <Column className="tenant-info-col">
										<label className="form-label">Request Date</label>
										<span className="info-text">
											{format(new Date(booking.created_at), "MMM dd, yyyy")}
										</span>
									</Column> */}
									</Row>
									<Row>
										<Column className="tenant-info-col">
											<label className="form-label">Unit</label>
											<span className="info-text">
												{booking.unit.tower} {booking.unit.name}
											</span>
										</Column>
									</Row>
									<Row>
										<Column className="tenant-info-col">
											<label className="form-label">Check In Time</label>
											<span className="info-text">
												{format(
													new Date(booking.arrival),
													"MMM dd, yyyy (hh:mm a)"
												)}
											</span>
										</Column>
									</Row>
									<Row>
										<Column className="tenant-info-col">
											<label className="form-label">Check Out Time</label>
											<span className="info-text">
												{format(
													new Date(booking.departure),
													"MMM dd, yyyy (hh:mm a)"
												)}
											</span>
										</Column>
									</Row>
									<Row>
										<Column className="tenant-info-col">
											<label className="form-label">Adults</label>
											<span className="info-text">{booking.adults}</span>
										</Column>
										<Column className="tenant-info-col">
											<label className="form-label">Children</label>
											<span className="info-text">{booking.children}</span>
										</Column>
									</Row>
									<Row>
										<Column className="tenant-info-col">
											<label className="form-label">Self Check In</label>
											<span className="info-text">
												{booking.self_checkin ? "Yes" : "No"}
											</span>
										</Column>
										<Column className="tenant-info-col">
											<label className="form-label">Self Check Out</label>
											<span className="info-text">
												{booking.self_checkout ? "Yes" : "No"}
											</span>
										</Column>
									</Row>
									<Row className="mt-5 mb-3">
										<Column className="p-0">
											<span className="tenant-guests-header">
												Primary Guest
											</span>
										</Column>
									</Row>
									<div className="guest-row mb-5">
										<Row className="tenant-header-row mt-2">
											<Column>
												<span className="white-text">
													{booking.primary_guest_name}
												</span>
											</Column>
										</Row>
										<Row>
											<Column className="tenant-info-col">
												<label className="form-label">Age</label>
												<span className="info-text">{primaryGuest.age}</span>
											</Column>
											<Column className="tenant-info-col">
												<label className="form-label">Nationality</label>
												<span className="info-text">
													{primaryGuest.nationality}
												</span>
											</Column>
										</Row>
										<Row>
											<Column className="tenant-info-col">
												<label className="form-label">Address</label>
												<span className="info-text">
													{primaryGuest.address}
												</span>
											</Column>
										</Row>
										<Row>
											<Column className="tenant-info-col">
												<label className="form-label">Email</label>
												<span className="info-text">{primaryGuest.email}</span>
											</Column>
										</Row>
										<Row>
											<Column className="tenant-info-col">
												<label className="form-label">Contact No.</label>
												<span className="info-text">{primaryGuest.msisdn}</span>
											</Column>
										</Row>
										<Row>
											<Column className="tenant-info-col">
												<label className="form-label">Vehicle</label>
												<span className="info-text">
													{primaryGuest.vehicle.length !== 0
														? `${primaryGuest.vehicle[0].model} (${primaryGuest.vehicle[0].type}) - ${primaryGuest.vehicle[0].plate_no}`
														: "N/A"}
												</span>
											</Column>
											<Column className="tenant-info-col">
												<label className="form-label">Parking</label>
												<span className="info-text">
													{primaryGuest.vehicle.length !== 0 &&
													primaryGuest.vehicle[0].with_parking
														? `${primaryGuest.vehicle[0].parking_tower} - ${primaryGuest.vehicle[0].parking_level}, ${primaryGuest.vehicle[0].parking_slot}`
														: "N/A"}
												</span>
											</Column>
										</Row>
										<Row>
											<Column className="info-col">
												<label className="form-label">Valid ID</label>
												{primaryGuest.id_img ? (
													<BookingImg
														className="tenant-booking-img"
														src={primaryGuest.id_img}
													/>
												) : (
													"N/A"
												)}
											</Column>
											<Column className="info-col">
												<label className="form-label">Vax Cert.</label>
												{primaryGuest.vax_img ? (
													<BookingImg
														className="tenant-booking-img"
														src={primaryGuest.vax_img}
													/>
												) : (
													"N/A"
												)}
											</Column>
										</Row>
										{booking.guests.filter((guest) => !guest.is_primary)
											.length > 0 && (
											<Row className="mt-5 mb-3">
												<Column className="p-0">
													<span className="tenant-guests-header">
														Additional Guests
													</span>
												</Column>
											</Row>
										)}
										{booking.guests
											.filter((guest) => !guest.is_primary)
											.map((guest) => (
												<div key={guest.id} className="guest-row mb-5">
													<Row className="tenant-header-row mt-2">
														<Column>
															<span className="white-text">{guest.name}</span>
														</Column>
													</Row>
													<Row>
														<Column className="tenant-info-col">
															<label className="form-label">Age</label>
															<span className="info-text">{guest.age}</span>
														</Column>
														<Column className="tenant-info-col">
															<label className="form-label">Below 3 Feet</label>
															<span className="info-text">
																{guest.below_min_height ? "Yes" : "No"}
															</span>
														</Column>
													</Row>
													<Row>
														<Column className="tenant-info-col">
															<label className="form-label">Vehicle</label>
															<span className="info-text">
																{guest.vehicle.length !== 0
																	? `${guest.vehicle[0].model} (${guest.vehicle[0].type}) - ${guest.vehicle[0].plate_no}`
																	: "N/A"}
															</span>
														</Column>
														<Column className="tenant-info-col">
															<label className="form-label">Parking</label>
															<span className="info-text">
																{guest.vehicle.length !== 0 &&
																guest.vehicle[0].with_parking
																	? `${guest.vehicle[0].parking_tower} - ${guest.vehicle[0].parking_level}, ${guest.vehicle[0].parking_slot}`
																	: "N/A"}
															</span>
														</Column>
													</Row>
													<Row>
														<Column className="info-col">
															<label className="form-label">Valid ID</label>
															{guest.id_img ? (
																<BookingImg
																	className="tenant-booking-img"
																	src={guest.id_img}
																/>
															) : (
																"N/A"
															)}
														</Column>
														<Column className="info-col">
															<label className="form-label">Vax Cert.</label>
															{guest.vax_img ? (
																<BookingImg
																	className="tenant-booking-img"
																	src={guest.vax_img}
																/>
															) : (
																"N/A"
															)}
														</Column>
													</Row>
												</div>
											))}
									</div>
									<Row className="mt-5 mb-3">
										<Column className="p-0">
											<span className="tenant-guests-header">Remarks</span>
										</Column>
									</Row>
									<Row>
										<Column className="tenant-info-col">
											<p className="info-text">{booking.remarks || "N/A"}</p>
										</Column>
									</Row>
									{booking.qr_path && (
										<div className="flex-column full mt-4">
											<img
												src={booking.qr_path.replace(
													("stlc.sgp1.cdn.digitaloceanspaces.com/bookings",
													"apms-bucket.sgp1.cdn.digitaloceanspaces.com/bookings")
												)}
												alt=""
												className="booking-qr"
											/>
											<a
												href={booking.qr_path.replace(
													("stlc.sgp1.cdn.digitaloceanspaces.com/bookings",
													"apms-bucket.sgp1.cdn.digitaloceanspaces.com/bookings")
												)}
												download
											>
												<Button variant="outline-primary text-sm mb-4">
													Download QR Code
												</Button>
											</a>
											<Button
												variant="light"
												className="text-sm"
												onClick={regenQr}
											>
												Regenerate QR Code
											</Button>
										</div>
									)}
									{booking.status !== "Checked In" &&
										booking.status !== "Checked Out" &&
										booking.status !== "Cancelled" &&
										bookingDate >= mayFirst2023 && (
											<div className="wizard-btn-container">
												<Row>
													<Column>
														<Link
															to={`/bookings/${id}/edit${window.location.search}`}
														>
															<Button variant="primary" className="text-sm">
																Edit Booking
															</Button>
														</Link>
													</Column>
												</Row>
											</div>
										)}
								</Container>
							</Tab>
							<Tab
								eventKey="access"
								title="Pool Access"
								tabClassName="tenant-booking-tab"
							>
								<Container>
									{booking.guests.map((guest) => (
										<GuestPool key={guest.id} guest_id={guest.id} />
									))}
								</Container>
							</Tab>
						</Tabs>
					</div>
				)}
			</Section>
		</>
	);
};

export default TenantBookingInfo;

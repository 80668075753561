import { useState, useEffect, useContext, useRef } from "react";
import { TenantContext } from "../context/TenantContext";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Skeleton } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { FileUploadOutlined } from "@mui/icons-material";
import AWS from "aws-sdk";

const BookingImg = ({ guestId, className, src, setShowImage }) => {
	const videoRef = useRef(null);
	const canvasRef = useRef(null);
	const [tenantUser] = useContext(TenantContext);
	const [img, setImg] = useState(null);
	const [imageSrc, setImageSrc] = useState(null);
	const [loading, setLoading] = useState(true);
	const [uploading, setUploading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [preview, setPreview] = useState(null);
	const [showModal, setShowModal] = useState(false);

	const S3_BUCKET = "apms-bucket";

	AWS.config.update({
		accessKeyId: process.env.REACT_APP_STORAGE_KEY_ID,
		secretAccessKey: process.env.REACT_APP_STORAGE_ACCESS_KEY,
	});

	const myBucket = new AWS.S3({
		endpoint: "sgp1.digitaloceanspaces.com",
		params: { Bucket: S3_BUCKET },
	});

	const handleFileUpload = () => {
		setUploading(true);
		const filename = `${uuidv4()}.png`;

		const base64Data = imageSrc.split(",")[1];

		const blob = new Blob(
			[
				new Uint8Array(
					atob(base64Data)
						.split("")
						.map((c) => c.charCodeAt(0))
				),
			],
			{
				type: "image/png",
			}
		);

		const params = {
			Body: blob,
			Bucket: S3_BUCKET,
			Key: `bookings/${filename}`,
			ACL: "private",
		};

		myBucket
			.putObject(params)
			.on("httpUploadProgress", (evt) => {
				setProgress(Math.round((evt.loaded / evt.total) * 100));
			})
			.send((err) => {
				if (err) console.log(err);
				else {
					setPreview(imageSrc);
				}
				setUploading(false);
			});
	};

	const getImg = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": tenantUser ? tenantUser.auth_token : "",
			},
		};

		const response = await fetch(
			src.replace("api.napatcharm.com", "apms.jasperbarcelona.com"),
			requestOptions
		);

		if (!response.ok) {
			setImg(null);
		} else {
			const data = await response.json();
			setImg(data.url);
		}
		setLoading(false);
	};

	const startWebcam = async () => {
		const stream = await navigator.mediaDevices.getUserMedia({
			video: true,
		});
		videoRef.current.srcObject = stream;
	};

	// Capture photo from webcam
	const capturePhoto = () => {
		const canvas = canvasRef.current;
		const video = videoRef.current;
		canvas.width = video.videoWidth;
		canvas.height = video.videoHeight;
		const context = canvas.getContext("2d");
		context.drawImage(video, 0, 0, canvas.width, canvas.height);
		setImageSrc(canvas.toDataURL("image/png"));
		stopWebcam();
		setShowModal(false);
	};

	// Stop webcam stream
	const stopWebcam = () => {
		const stream = videoRef.current.srcObject;
		if (stream) {
			const tracks = stream.getTracks();
			tracks.forEach((track) => track.stop());
			videoRef.current.srcObject = null;
		}
	};

	useEffect(() => {
		if (imageSrc) {
			handleFileUpload();
		}
	}, [imageSrc]);

	useEffect(() => {
		if (src) {
			getImg();
		} else {
			setLoading(false);
		}
	}, [src]);

	return loading ? (
		<Skeleton
			variant="rounded"
			width="100%"
			height={className.includes("tenant") ? 100 : 250}
			className={`booking-skeleton ${className}`}
		/>
	) : img ? (
		<div
			className={className}
			onClick={() => setShowImage(img)}
			style={{
				background: `url(${img})`,
			}}
		/>
	) : (
		"N/A"
		// <>
		// 	<div
		// 		className="wizard-upload-preview flex-column"
		// 		style={{
		// 			background: preview ? `url(${preview})` : "#eee",
		// 		}}
		// 		onClick={() => {
		// 			setShowModal(true);
		// 			startWebcam();
		// 		}}
		// 	>
		// 		{!preview && !uploading && <FileUploadOutlined sx={{ fontSize: 24 }} />}

		// 		{uploading && (
		// 			<>
		// 				<div className="upload-progress-overlay" />
		// 				<div
		// 					className="upload-progress-bar"
		// 					style={{
		// 						width: `${progress < 20 ? 20 : progress}%`,
		// 					}}
		// 				/>
		// 			</>
		// 		)}
		// 	</div>
		// 	<Modal
		// 		show={showModal}
		// 		onHide={() => setShowModal(false)}
		// 		size="lg"
		// 		className="photo-modal"
		// 	>
		// 		<Modal.Header>
		// 			<Modal.Title className="popup-modal-header medium-text">
		// 				Upload Photo
		// 			</Modal.Title>
		// 		</Modal.Header>
		// 		<Modal.Body>
		// 			<video ref={videoRef} autoPlay width="100%"></video>
		// 			<canvas ref={canvasRef} />
		// 		</Modal.Body>
		// 		<Modal.Footer>
		// 			<Button
		// 				variant="outline-dark"
		// 				className="modal-btn bold-text text-sm me-2 dismiss-btn"
		// 				onClick={() => setShowModal(false)}
		// 			>
		// 				Cancel
		// 			</Button>
		// 			<Button
		// 				type="submit"
		// 				variant="primary"
		// 				className="modal-btn bold-text text-sm"
		// 				onClick={capturePhoto}
		// 			>
		// 				Capture
		// 			</Button>
		// 		</Modal.Footer>
		// 	</Modal>
		// </>
	);
};

export default BookingImg;

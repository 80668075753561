import { useEffect, useState, useContext } from "react";
import { TenantContext } from "../context/TenantContext";
import { FileUploadOutlined } from "@mui/icons-material";

const ImageUpload = ({
	additionalErrors,
	previewImg,
	uploading,
	progress,
	inputLabel,
	index,
}) => {
	const [tenantUser] = useContext(TenantContext);
	const [imageUrl, setImageUrl] = useState("");

	const getImage = async (src) => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": tenantUser ? tenantUser.auth_token : "",
			},
		};

		const response = await fetch(src, requestOptions);
		if (!response.ok) {
			return "";
		}

		const data = await response.json();
		setImageUrl(data.url);
	};

	useEffect(() => {
		if (previewImg) {
			getImage(previewImg);
		}
	}, [previewImg]);

	return (
		<div
			className={`wizard-upload-preview flex-column ${
				additionalErrors.includes("id" + index) && "border-danger"
			}`}
			style={{
				background: imageUrl ? `url(${imageUrl})` : "#eee",
			}}
			onClick={() => document.getElementById(`${inputLabel}${index}`).click()}
		>
			{!previewImg && !uploading && (
				<FileUploadOutlined sx={{ fontSize: 24 }} />
			)}

			{uploading && (
				<>
					<div className="upload-progress-overlay" />
					<div
						className="upload-progress-bar"
						style={{
							width: `${progress < 20 ? 20 : progress}%`,
						}}
					/>
				</>
			)}
		</div>
	);
};

export default ImageUpload;
